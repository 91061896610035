<template>
  <div class="row">
    <EmptyState
      v-if="!hasCreditCards"
      :to="{ name: 'create-credit-card' }"
      icon="jbsmd-wallet"
      :message="$gettext('You don\'t have any credit cards')"
      :customButtonText="$gettext('Add a credit card')"
    />
    <div v-show="hasCreditCards" class="col-md-12 ">
      <ContentHeader :title="$gettext('Credit cards')">
        <template #options>
          <router-link
            class="btn btn-primary align-self-baseline"
            to="create-credit-card"
            ><i class="fas fa-plus mr-2"></i
            ><translate>Add credit card</translate></router-link
          >
        </template>
      </ContentHeader>
      <div class="card mt-3">
        <div class="card-body">
          <b-table :items="creditCards" :fields="fields">
            <template #cell(is_default)="{item}">
              <i v-if="item.is_default" class="fa fa-check text-success"></i>
            </template>
            <template #cell(created_at)="{item}">
              <FormattedDate :date="item.created_at" />
            </template>
            <template #cell(updated_at)="{item}">
              <FormattedDate :date="item.updated_at" />
            </template>
            <template #cell(actions)="{item}">
              <b-button
                :disabled="isPosting || item.is_default"
                @click="handleDeleteCard(item)"
                variant="danger"
                class="btn btn-icon btn-sm"
                v-b-tooltip.hover
                :title="$gettext('Delete card')"
                ><i class="fa fa-times"></i
              ></b-button>
              <b-button
                v-if="!item.is_default"
                :disabled="isPosting"
                variant="success"
                class="btn btn-icon btn-sm ml-1"
                @click="handleSetDefaultCard(item)"
                v-b-tooltip.hover
                :title="$gettext('Set as default card')"
                ><i class="fa fa-check"></i
              ></b-button>
            </template>
          </b-table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import { mapActions, mapGetters } from "vuex";
import EmptyState from "@/components/EmptyState";
import ContentHeader from "@/components/ContentHeader";
import FormattedDate from "@/components/FormattedDate";
import { VBTooltip } from "bootstrap-vue";
import { msgBoxConfirm } from "@/utils/modal";
Vue.directive("b-tooltip", VBTooltip);
export default {
  components: {
    EmptyState,
    FormattedDate,
    ContentHeader
  },
  data: () => ({
    isPosting: false
  }),
  created() {
    this.fetch().finally(this.stopFetching);
  },
  computed: {
    ...mapGetters("app", ["isFetching"]),
    ...mapGetters("creditCards", ["creditCards", "hasCreditCards"]),
    fields() {
      return [
        { label: this.$gettext("Brand"), key: "brand" },
        { label: this.$gettext("Name"), key: "name" },
        { label: this.$gettext("exp_month"), key: "exp_month" },
        { label: this.$gettext("exp_year"), key: "exp_year" },
        { label: this.$gettext("Default"), key: "is_default" },
        { label: this.$gettext("created_at"), key: "created_at" },
        { label: this.$gettext("updated_at"), key: "updated_at" },
        "actions"
      ];
    }
  },
  methods: {
    ...mapActions("app", ["stopFetching"]),
    ...mapActions("creditCards", {
      fetch: "fetchAll",
      deleteCreditCard: "delete",
      setDefaultCard: "setDefaultCard"
    }),
    handleDeleteCard({ id }) {
      this.$root.$emit("bv::hide::tooltip");
      msgBoxConfirm().then(value => {
        if (value.isConfirmed === true) {
          this.isPosting = true;
          this.deleteCreditCard(id).finally(() => {
            this.fetch().finally(() => (this.isPosting = false));
          });
        }
      });
    },
    async handleSetDefaultCard({ id }) {
      this.isPosting = true;
      this.$root.$emit("bv::hide::tooltip");
      await this.setDefaultCard(id).finally(() => {
        this.fetch().finally(() => (this.isPosting = false));
      });
    }
  }
};
</script>
